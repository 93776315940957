export default [
  {
    path: '/:prefix(test)?/branches',
    name: 'BranchListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'BranchListCreate',
      requireAuth: true,
      requireAccount: true,
      title: 'Tiendas'
    },
    component: () => import('@/modules/pos/branch/views/BranchListCreate')
  },
  {
    path: '/:prefix(test)?/branches/:id',
    name: 'BranchRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'BranchListCreate',
      requireAuth: true,
      requireAccount: true,
      title: 'Información de la tienda'
    },
    props: true,
    component: () => import('@/modules/pos/branch/views/BranchRetrieveUpdate')
  },
  {
    path: '/:prefix(test)?/pos',
    name: 'PosListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'BranchListCreate',
      requireAuth: true,
      requireAccount: true,
      title: 'Puntos de venta'
    },
    component: () => import('@/modules/pos/pos/views/PosListCreate')
  },
  {
    path: '/:prefix(test)?/pos/:id',
    name: 'PosRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'BranchListCreate',
      requireAuth: true,
      requireAccount: true,
      title: 'Información del punto de venta'
    },
    component: () => import('@/modules/pos/pos/views/PosRetrieveUpdate')
  }
]