const customersRoutes = [
  {
    path: '/:prefix(test)?/sire',
    name: 'SireList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      title: 'SIRE'
    },
    component: () => import('@/modules/sire/views/SireList')
  }
]
export default customersRoutes