const accountRoutes = [
  {
    path: "/:prefix(test)?/account/settings",
    name: "AccountMain",
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: "AccountMain",
      requireAuth: true,
      requireAccount: true,
      showDetails: true,
      use: ["fc", "ax"],
      title: "Configuración de la organización"
    },
    component: () => import("@/modules/account/views/Main"),
    children: [
      {
        path: "/:prefix(test)?/account/settings/basic",
        name: "AccountBasic",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          showDetails: true,
          title: "Configuración de la organización",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "Organización" }]
          }
        },
        component: () => import("@/modules/account/views/Basic")
      },
      {
        path: "/:prefix(test)?/account/settings/notifications",
        name: "AccountNotifications",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          use: ["ax"],
          showSupport: true,
          title: "Notificaciones"
        },
        component: () => import("@/modules/account/views/Notifications")
      },
      {
        path: "/:prefix(test)?/account/settings/irs/built_in",
        name: "IrsBuiltInSettings",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          title: "SII",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "SII" }]
          }
        },
        component: () => import("@/modules/account/views/irs/sii/Settings")
      },
      {
        path: "/:prefix(test)?/account/settings/members",
        name: "AccountMembers",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['view_member', 'change_member', 'delete_member', 'view_invitation', 'add_invitation', 'delete_invitation'],
          title: "Usuarios",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "Directorio" }, { text1: "Usuarios" }]
          }
        },
        component: () => import("@/modules/account/views/members/Members")
      },
      {
        path: "/:prefix(test)?/account/settings/members/:id/edit",
        name: "AccountMemberUpdate",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['change_member'],
          parentTitle: "Usuarios",
          title: "Actualizar usuario",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "Directorio" }, { text1: "Usuarios" }]
          }
        },
        component: () =>
          import("@/modules/account/views/members/MembersUpdate")
      },
      {
        path: "/:prefix(test)?/account/settings/roles",
        name: "AccountGroups",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['view_group', 'add_group', 'change_group'],
          title: "Roles",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "Directorio" }, { text1: "Grupos" }]
          }
        },
        component: () => import("@/modules/account/views/groups/Groups")
      },
      {
        path: "/:prefix(test)?/account/settings/roles/create",
        name: "AccountGroupsCreate",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['add_group'],
          title: "Crear rol",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "Directorio" }, { text1: "Grupos" }]
          }
        },
        component: () =>
          import("@/modules/account/views/groups/GroupsCreateUpdate")
      },
      {
        path: "/:prefix(test)?/account/settings/roles/:id/edit",
        name: "AccountGroupsUpdate",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['change_group', 'delete_group'],
          title: "Actualizar rol",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "Directorio" }, { text1: "Grupos" }]
          }
        },
        component: () =>
          import("@/modules/account/views/groups/GroupsCreateUpdate")
      },
      {
        path: "/:prefix(test)?/account/settings/pos",
        name: "AccountSendLogPos",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          title: "Configuración de POS",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "POS" }]
          }
        },
        component: () => import("@/modules/account/views/AccountSendLogPos")
      },
      {
        path: "/:prefix(test)?/account/settings/webhooks",
        name: "Developers",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          title: "Webhooks",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "Developers" }]
          }
        },
        component: () => import("@/modules/account/views/Developers")
      }
    ]
  },
  {
    path: '/:prefix(test)?/account/dashboard',
    name: 'AccountDashboard',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'AccountDashboard',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      title: 'Dashboard'
    },
    component: () => import('@/modules/account/views/DashboardAX')
  }
]
export default accountRoutes
