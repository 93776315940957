const customersRoutes = [
  {
    path: '/:prefix(test)?/customers',
    name: 'CustomerListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      title: 'Clientes',
      breadcrumbs: [
        {
          text: 'Clientes',
          to: { name: 'CustomerListCreate' },
          link: true,
          exact: true,
          disabled: false
        }
      ]
    },
    component: () => import('@/modules/customer/views/CustomerListCreate')
  }
]
export default customersRoutes