<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="630"
      overlay-color="grey-500"
    >
      <v-card class="text-center">
        <v-toolbar class="px-4" color="transparent" flat height="48">
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" class="pt-7 mr-4" justify="center">
              <v-btn @click="dialog=false" icon small><v-icon size="20" color="grey-500">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pb-7">
          <v-img contain :height="190" :src="require(`@/assets/alert-dialog/ax.svg`)" />
          <span class="d-block subtitle-1 mt-4 font-weight-bold blue-500--text">SUNAT INFORMA</span>
          <v-col class="text-left pb-0">
            Cumplimos con informar que SUNAT tiene programado un mantenimiento el día de hoy, lo cual afecta la emisión y recepción de los documentos electrónicos.
            <!-- <ul class="pl-6 mt-3">
              <li>Envío de guías de remisión a SUNAT.</li>
              <li>Intermitencia en la recepción por SUNAT de los comprobantes emitidos.</li>
              <li>Validación de comprobantes recibidos; es decir, todo lo que envían los proveedores.</li>
            </ul> -->
            <span class="d-block mt-3">Una vez finalizado el mantenimiento, Axteroid se encargará de regularizar todos los documentos pendientes.</span>

            <span class="d-block mt-4">
              Para más información: <a class="text-decoration-none" href="https://www.sunat.gob.pe/mensajes/diciembre/2024/aviso-ti-091224.html" target="_blank">Consulta aquí</a>
            </span>
            <span class="mt-3 d-block">Atentamente, <span class="d-block">Equipo Axteroid.</span></span>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    data () {
    return {
      dialog: false
    }
  },
  created () {
    this.dialog = true
  }
}
</script>