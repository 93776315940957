import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// MODULES
import analytics from './modules/analytics'
import accounts from './modules/accounts'
import irs from './modules/irs'
import base from './modules/base'
import global from './modules/global'
import auth from './modules/auth'
import customers from './modules/customers'
import documents from './modules/documents'
import messages from './modules/messages'
import purchases from './modules/purchases'
import batch from './modules/batch'
import pos from './modules/pos'
import branches from './modules/branches'
import summaries from './modules/summaries'
import reconciliations from './modules/reconciliations'

Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  strict: true,
  modules: {
    analytics,
    accounts,
    irs,
    base,
    global,
    auth,
    customers,
    documents,
    messages,
    purchases,
    batch,
    pos,
    branches,
    summaries,
    reconciliations
  },
  plugins: [
    createPersistedState({
      paths: ['auth']
    })
  ]
})
