import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'

// HTTP
import httpRoutes from '@/modules/http/routes'

// AUTH
import authRoutes from '@/modules/auth/routes'

// DOCUMENTS
import documentRoutes from '@/modules/documents/routes'

// ACCOUNT
import accountRoutes from '@/modules/account/routes'

// CUSTOMERS
import customersRoutes from '@/modules/customer/routes'

import purchaseRoutes from '@/modules/purchase/routes'

// POS
import posRoutes from '@/modules/pos/routes'

// SUMMARIES
import summariesRoutes from '@/modules/summaries/routes'

// METRICS
import metricsRoutes from '@/modules/metrics/routes'

// CUADRATURA
import reconciliationsRoutes from '@/modules/reconciliations/routes'

// SIRE
import sireRoutes from '@/modules/sire/routes'

Vue.use(VueRouter)
const routes = [
  ...authRoutes,
  ...accountRoutes,
  ...customersRoutes,
  ...purchaseRoutes,
  ...httpRoutes,
  ...documentRoutes,
  ...posRoutes,
  ...summariesRoutes,
  ...metricsRoutes,
  ...reconciliationsRoutes,
  ...sireRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('global/CANCEL_PENDING_REQUESTS')
  if (to.meta.requireAuth) {
    if (store.state.auth.token) {
      if (to.meta.requireAccount) {
        if (store.state.auth.account.id) {
          if (!store.state.auth.userAccount.is_staff) {
            if (store.state.accounts.me?.id === undefined) {
              await store.dispatch('accounts/GET_ME', {
                resource: 'members/me/?expand=groups'
              })
              if (to.meta.permissionsRequired !== undefined && !store.state.accounts.me?.is_owner && !to.meta.permissionsRequired?.some((l) => store.state.accounts.me.permissions?.some((v) => v === l))) {
                next({ name: 'PermissionDenied' })
              } else next()
            } else {
              if (to.meta.permissionsRequired !== undefined && !store.state.accounts.me?.is_owner && !to.meta.permissionsRequired?.some((l) => store.state.accounts.me.permissions?.some((v) => v === l))) {
                next({ name: 'PermissionDenied' })
              } else next()
            }
          } else next()
        } else {
          next({ path: '/account/choose' })
        }
      } else {
        next()
      }
    } else {
      next({
        path: '/auth/signin',
        query: { redirect: to.fullPath }
      })
      store.dispatch('auth/GET_USER')
    }
  } else {
    if (to.name === 'AuthSignUp') {
      if (to.query.invitation) {
        next()
      }
    }
    next()
  }
})

router.afterEach((to, from) => {
  if (store.state.auth.account.id) {
    // track Segment page navigation
    if (process.env.NODE_ENV === 'production') {
      window.analytics.page(to.name)
    }
  }
})

router.beforeEach((to, from, next) => {
  const currentClient = 'Axteroid'
  document.title = to.meta.title ? to.meta.title + ` - ${currentClient}` : currentClient
  next(true)
})

export default router
